import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import logo from '../img/Song Insider - Orange - A.png';
import logoRadioVillaSound from '../img/radiovillasound.png';
import logoHaptic from '../img/haptic.jpg';

const sections = [
    {
        title: 'SongInsider',
        content: 'Mi piace descriverlo come un salottino dove si condividono pensieri, canzoni e ci si racconta attraverso la musica',
        image: logo,
    },
    {
        content: (
            <>
                Il progetto è nato da un'idea di Biagio Eugenio Iaria ed ha trovato la prima modalità di espressione attraverso un programma Radio in una piccola Radio locale chiamata{' '}
                <Link href="https://www.villasound.net/" target="_blank" rel="noopener noreferrer">
                    RadioVillaSound
                </Link>
            </>
        ),
        image: logoRadioVillaSound,
        link: 'https://www.villasound.net/',
    },
    {
        content: (
            <>
                Poco dopo è nata una pagina Instagram (
                <Link href="https://www.instagram.com/songinsider/" target="_blank" rel="noopener noreferrer">
                    SongInsider
                </Link>)
                grazie all'aiuto di un caro amico{' '}
                <Link href="https://www.instagram.com/gfxhaptic/" target="_blank" rel="noopener noreferrer">
                    gfxHaptic
                </Link>, che ha curato le grafiche e ha supportato il progetto fin dal giorno 0. Instagram è stato molto
                importante perché lì sono stati pubblicati i primi articoli, le prime recensioni e le prime interviste.
            </>
        ),
        image: logoHaptic,
        link: 'https://www.instagram.com/gfxhaptic/',
    },
    {
        content: (
            <>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                    <Box sx={{ flexBasis: '33.33%', marginRight: '8px' }}>
                        <Link href="https://www.twitch.tv/songinsider" target="_blank" rel="noopener noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" height="200" viewBox="0 0 24 24">
                                <path fill="#6441a5" d="M11.64 5.93h1.43v4.28h-1.43m3.93-4.28H17v4.28h-1.43M7 2L3.43 5.57v12.86h4.28V22l3.58-3.57h2.85L20.57 12V2m-1.43 9.29l-2.85 2.85h-2.86l-2.5 2.5v-2.5H7.71V3.43h11.43Z"/>
                            </svg>
                        </Link>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        Il programma Radio non si è mai fermato in questi anni, e anzi siamo approdati anche su Twitch, ovvero una piattaforma di streaming in diretta, dove sono nati nuovi format.
                    </Box>
                </Box>
            </>
        ),
    }
];

const colors = [
    { background: '#f5f5f5', text: '#000' },
    { background: '#e0e0e0', text: '#000' },
];

const SongInsider = () => {
    return (
        <Box sx={{ width: '100%', padding: '20px 0' }}>
            {sections.map((section, index) => (
                <Box
                    key={index}
                    sx={{
                        width: '100%',
                        padding: '20px',
                        backgroundColor: colors[index % colors.length].background,
                        color: colors[index % colors.length].text,
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        boxSizing: 'border-box',
                        marginBottom: '20px',
                    }}
                >
                    <Grid container spacing={2} alignItems="center">
                        {section.image && (
                            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                                {section.link ? (
                                    <Link href={section.link} target="_blank" rel="noopener noreferrer">
                                        <Box
                                            component="img"
                                            src={section.image}
                                            alt={`Section image ${index + 1}`}
                                            sx={{
                                                width: '200px',
                                                height: '200px',
                                                borderRadius: '8px',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Link>
                                ) : (
                                    <Box
                                        component="img"
                                        src={section.image}
                                        alt={`Section image ${index + 1}`}
                                        sx={{
                                            width: '200px',
                                            height: '200px',
                                            borderRadius: '8px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                )}
                            </Grid>
                        )}
                        <Grid item xs={12} md={section.image ? 8 : 12}>
                            {section.title && (
                                <Typography variant="h4" component="h2" gutterBottom>
                                    {section.title}
                                </Typography>
                            )}
                            <Typography variant="body1">
                                {section.content}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            ))}
        </Box>
    );
};

export default SongInsider;
