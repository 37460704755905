import { BrowserRouter as Router, Link, Route, Routes, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './App.css';
import {
    AppBar,
    Toolbar,
    Box,
    Container,
    IconButton,
    Grid,
    Typography,
    Dialog,
    List,
    ListItem,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Home from "./Page/Home";
import logo from './img/Song Insider - Orange - A.png';
import Articles from "./Page/Articles";
import Post from "./Page/Post";
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import SongInsider from "./Page/SongInsider";
import Interact from "./Page/Interact";
import Releases from "./Page/Releases";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    return (
        <div className="App">
            <Router>
                <ScrollToTop />
                {Header()}
                <article>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/articles" element={<Articles />} />
                        <Route path="/:id" element={<Post />} />
                        <Route path="/SongInsider" element={<SongInsider />} />
                        <Route path="/Interact" element={<Interact />} />
                        <Route path="/Releases" element={<Releases />} />
                    </Routes>
                </article>
                {Footer()}
            </Router>
        </div>
    );
}

function Header() {
    const [open, setOpen] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [newsMenuOpen, setNewsMenuOpen] = useState(false); // Stato per il sottomenù delle News

    const handleMouseEnter = (index: number) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const handleMenuClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setHoveredIndex(null);
        setOpen(false);
    };

    const handleNewsClick = () => {
        setNewsMenuOpen(!newsMenuOpen);
    };

    const handleSubMenuClick = () => {
        handleClose();
        setNewsMenuOpen(false);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: '#212121' }}>
            <Toolbar>
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                    <img src={logo} alt="Logo" style={{ height: '85px', marginRight: '16px' }} />
                </Link>
                <div style={{ flexGrow: 1 }} />
                <IconButton
                    edge="end"
                    color="inherit"
                    onClick={handleMenuClick}
                    aria-label="menu"
                >
                    <MenuIcon />
                </IconButton>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    PaperProps={{ sx: { backgroundColor: '#ff3801' } }}
                >
                    <Box sx={{ position: 'absolute', top: 16, right: 16 }}>
                        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            textAlign: 'center',
                            color: '#fff'
                        }}
                    >
                        <List>
                            <ListItem
                                onClick={handleClose}
                                onMouseEnter={() => handleMouseEnter(0)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Link
                                    to={'/articles?type=article'}
                                    style={{
                                        textDecoration: 'none',
                                        color: hoveredIndex === 0 ? 'black' : 'inherit',
                                    }}
                                >
                                    <Typography variant="h4">Articoli</Typography>
                                </Link>
                            </ListItem>
                            <ListItem
                                onClick={handleClose}
                                onMouseEnter={() => handleMouseEnter(1)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Link
                                    to={'/articles?type=review'}
                                    style={{
                                        textDecoration: 'none',
                                        color: hoveredIndex === 1 ? 'black' : 'inherit',
                                    }}
                                >
                                    <Typography variant="h4">Recensioni</Typography>
                                </Link>
                            </ListItem>
                            <ListItem
                                onClick={handleClose}
                                onMouseEnter={() => handleMouseEnter(2)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Link
                                    to={'/articles?type=interview'}
                                    style={{
                                        textDecoration: 'none',
                                        color: hoveredIndex === 2 ? 'black' : 'inherit',
                                    }}
                                >
                                    <Typography variant="h4">Interviste</Typography>
                                </Link>
                            </ListItem>
                            <ListItem
                                onClick={handleNewsClick}
                                onMouseEnter={() => handleMouseEnter(3)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                    textDecoration: 'none',
                                    color: hoveredIndex === 3 ? 'black' : 'inherit',
                                    cursor: 'pointer',
                                }}
                            >
                                <Typography variant="h4">News</Typography>
                            </ListItem>
                            {newsMenuOpen && (
                                <ListItem sx={{ paddingLeft: '2em' }} onClick={handleSubMenuClick} onMouseEnter={() => handleMouseEnter(5)}>
                                    <Link
                                        to={'/Releases'}
                                        style={{
                                            textDecoration: 'none',
                                            color: hoveredIndex === 5 ? 'black' : 'inherit',
                                        }}
                                    >
                                        <Typography variant="h5">Ultime uscite</Typography>
                                    </Link>
                                </ListItem>
                            )}
                            <ListItem
                                onClick={handleClose}
                                onMouseEnter={() => handleMouseEnter(4)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <Link
                                    to={'/songinsider'}
                                    style={{
                                        textDecoration: 'none',
                                        color: hoveredIndex === 4 ? 'black' : 'inherit',
                                    }}
                                >
                                    <Typography variant="h4">About</Typography>
                                </Link>
                            </ListItem>
                        </List>
                    </Box>
                </Dialog>
            </Toolbar>
        </AppBar>
    );
}

function Footer() {
    return (
        <Box sx={{ backgroundColor: '#212121', color: '#fff', padding: '2em 0', marginTop: '0.5em' }}>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Seguici
                        </Typography>
                        <IconButton
                            component="a"
                            href="https://www.instagram.com/songinsider"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: '#fff' }}
                            aria-label="Instagram"
                        >
                            <InstagramIcon />
                        </IconButton>
                        <IconButton
                            component="a"
                            href="https://www.twitch.tv/songinsider"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: '#fff' }}
                            aria-label="Twitch"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                                <path fill="currentColor" d="M11.64 5.93h1.43v4.28h-1.43m3.93-4.28H17v4.28h-1.43M7 2L3.43 5.57v12.86h4.28V22l3.58-3.57h2.85L20.57 12V2m-1.43 9.29l-2.85 2.85h-2.86l-2.5 2.5v-2.5H7.71V3.43h11.43Z" />
                            </svg>
                        </IconButton>
                        <IconButton
                            component="a"
                            href="https://www.tiktok.com/@songinsider"
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{color: '#fff'}}
                            aria-label="TikTok"
                        >
                            <svg width="1em" height="1em" viewBox="0 0 48 48" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg">
                                <title>Tiktok</title>
                                <g id="Icon/Social/tiktok-white" stroke="none" strokeWidth="1" fill="none"
                                   fillRule="evenodd">
                                    <path
                                        d="M38.0766847,15.8542954 C36.0693906,15.7935177 34.2504839,14.8341149 32.8791434,13.5466056 C32.1316475,12.8317108 31.540171,11.9694126 31.1415066,11.0151329 C30.7426093,10.0603874 30.5453728,9.03391952 30.5619062,8 L24.9731521,8 L24.9731521,28.8295196 C24.9731521,32.3434487 22.8773693,34.4182737 20.2765028,34.4182737 C19.6505623,34.4320127 19.0283477,34.3209362 18.4461858,34.0908659 C17.8640239,33.8612612 17.3337909,33.5175528 16.8862248,33.0797671 C16.4386588,32.6422142 16.0833071,32.1196657 15.8404292,31.5426268 C15.5977841,30.9658208 15.4727358,30.3459348 15.4727358,29.7202272 C15.4727358,29.0940539 15.5977841,28.4746337 15.8404292,27.8978277 C16.0833071,27.3207888 16.4386588,26.7980074 16.8862248,26.3604545 C17.3337909,25.9229017 17.8640239,25.5791933 18.4461858,25.3491229 C19.0283477,25.1192854 19.6505623,25.0084418 20.2765028,25.0219479 C20.7939283,25.0263724 21.3069293,25.1167239 21.794781,25.2902081 L21.794781,19.5985278 C21.2957518,19.4900128 20.7869423,19.436221 20.2765028,19.4380839 C18.2431278,19.4392483 16.2560928,20.0426009 14.5659604,21.1729264 C12.875828,22.303019 11.5587449,23.9090873 10.7814424,25.7878401 C10.003907,27.666593 9.80084889,29.7339663 10.1981162,31.7275214 C10.5953834,33.7217752 11.5748126,35.5530237 13.0129853,36.9904978 C14.4509252,38.4277391 16.2828722,39.4064696 18.277126,39.8028054 C20.2711469,40.1991413 22.3382874,39.9951517 24.2163416,39.2169177 C26.0948616,38.4384508 27.7002312,37.1209021 28.8296253,35.4300711 C29.9592522,33.7397058 30.5619062,31.7522051 30.5619062,29.7188301 L30.5619062,18.8324027 C32.7275484,20.3418321 35.3149087,21.0404263 38.0766847,21.0867664 L38.0766847,15.8542954 Z"
                                        id="Fill-1" fill="#FFFFFF"></path>
                                </g>
                            </svg>
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Contattaci
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                component="a"
                                href="mailto:songinsider21@gmail.com"
                                sx={{color: '#fff'}}
                                aria-label="Email"
                            >
                                <EmailIcon/>
                                <Typography variant="body1" sx={{ color: '#fff', marginLeft: '0.5em'}}>
                                    songinsider21@gmail.com
                                </Typography>
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>

    );
}

export default App;
