import React from 'react';
import { Typography, Grid, Box, Container } from '@mui/material';
import EmailIcon from "@mui/icons-material/Email";

const Interact = () => {
    return (
        <Container maxWidth="md" sx={{ paddingLeft: 2, paddingRight: 2 }}>
            <Box sx={{
                textAlign: 'center',
                padding: 4,
                width: '100%',
                margin: '0 auto',
                overflowX: 'hidden',
                boxSizing: 'border-box',
            }}>
                <Typography variant="h4" gutterBottom>
                    Come interagire con SongInsider
                </Typography>

                <Grid container direction="column" spacing={2} alignItems="stretch">
                    <Grid item xs={12} sm={6} md={4}>
                        <Box sx={{
                            padding: 2,
                            border: '1px solid #ccc',
                            borderRadius: 2,
                            width: '100%',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Sei un'artista emergente?
                            </Typography>
                            <Typography variant="body2" paragraph>
                                Promuovi il tuo progetto su SongInsider! Invia la tua musica, video o qualsiasi materiale promozionale.
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Box sx={{
                            padding: 2,
                            border: '1px solid #ccc',
                            borderRadius: 2,
                            width: '100%',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Hai un commento o suggerimento?
                            </Typography>
                            <Typography variant="body2" paragraph>
                                Se hai domande o suggerimenti per migliorare il nostro servizio, siamo felici di ascoltarti!
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Box sx={{
                            padding: 2,
                            border: '1px solid #ccc',
                            borderRadius: 2,
                            width: '100%',
                            boxSizing: 'border-box',
                            overflow: 'hidden',
                        }}>
                            <Typography variant="h6" gutterBottom>
                                Vuoi collaborare con noi?
                            </Typography>
                            <Typography variant="body2" paragraph>
                                Se sei interessato a scrivere articoli, recensioni o fare interviste per SongInsider, contattaci! Siamo sempre alla ricerca di collaboratori appassionati per arricchire il nostro contenuto.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                    <EmailIcon sx={{ marginRight: 1 }} />
                    <a
                        href="mailto:songinsider21@gmail.com"
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                        }}
                    >
                        songinsider21@gmail.com
                    </a>
                </Box>
            </Box>
        </Container>
    );
};

export default Interact;
