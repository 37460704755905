import React from 'react';
import articlesData from '../json/articles.json';
import imageMap from '../imageMap';
import { Card, CardMedia, CardContent, Typography, Grid } from '@mui/material';
import {useLocation, useNavigate} from "react-router-dom";

function useParams() {
    return new URLSearchParams(useLocation().search);
}

function Articles() {

    let params = useParams();
    const type = params.get("type")

    const navigate = useNavigate();
    const goRouteId = (id: any) => {
        navigate(`/${id}?type=${type}`);
    }

    function comparePublicationDate(a:any, b:any) {
        const dateA = new Date(a.publication_date);
        const dateB = new Date(b.publication_date);

        return dateB.getTime() - dateA.getTime();
    }

    const sortedArticles = articlesData.sort(comparePublicationDate);

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, cardId: string) => {
        const card = document.getElementById(cardId);
        if (card) {
            const rect = card.getBoundingClientRect();
            const x = e.clientX - rect.left - rect.width / 2;
            const y = e.clientY - rect.top - rect.height / 2;
            card.style.transform = `rotateY(${x / 20}deg) rotateX(${-y / 20}deg)`;
        }
    };

    const handleMouseLeave = (cardId: string) => {
        const card = document.getElementById(cardId);
        if (card) {
            card.style.transform = `rotateY(0deg) rotateX(0deg)`;
        }
    };

    const getTitle = () => {
        if (type === 'article') {
            return 'Articoli';
        } else if (type === 'review') {
            return 'Recensioni';
        } else if (type === 'interview') {
            return 'Interviste';
        }
        return '';
    };

    return (
        <div className={'articles'}>
            <Typography variant="h4" component="h1" gutterBottom sx={{paddingTop: "0.5em"}}>
                {getTitle()}
            </Typography>
            <Grid container spacing={4}>
                {sortedArticles.map((article: any) => {
                    if (article['type'] === type) {
                        return (
                            <Grid item key={article['_id']} xs={12} sm={6} md={4}>
                                <div
                                    id={article['_id']}
                                    className="card-container"
                                    onMouseMove={(e) => handleMouseMove(e, article['_id'])}
                                    onMouseLeave={() => handleMouseLeave(article['_id'])}
                                    onClick={() => goRouteId(article['_id'])}
                                >
                                    <Card>
                                        <CardMedia
                                            component="img"
                                            height="300"
                                            width="100"
                                            image={imageMap[article._id]}
                                            alt={article.title}
                                        />
                                        <CardContent>
                                            <Typography variant="h5" component="div">
                                                {article.title}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </div>
                            </Grid>
                        );
                    }
                    return null;
                })}

            </Grid>
        </div>
    );
}

export default Articles;
