import React from 'react';
import { Typography, Container, Grid, Card, CardMedia } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import imageMap from "../imageMap";
import logo from '../img/Song Insider - Social Spotify.jpg';
import logo2 from '../img/Song Insider - Social-.jpg';
import { Link } from "react-router-dom";
import { useMediaQuery } from '@mui/material';

const Home = () => {
    const carouselItems = [
        {
            image: imageMap['Rap_Poesia'],
            description: 'Leggi gli articoli e le curiosità',
            link: '/articles?type=article'
        },
        {
            image: imageMap['NuovoSpazioTempo'],
            description: 'Leggi le recensioni degli Album.',
            link: '/articles?type=review'
        },
        {
            image: imageMap['SanLevigo_1'],
            description: 'Leggi le interviste',
            link: '/articles?type=interview'
        }
    ];
    const isMobile = useMediaQuery('(max-width:700px)');

    return (
        <div className={'home'}>
            <div style={{ width: '100%' }}>
                <Carousel>
                    {carouselItems.map((item, index) => (
                        <Link key={index} to={item.link} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card
                                style={{
                                    margin: '0 auto',
                                    height: isMobile ? '300px' : '700px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    overflow: 'hidden',
                                    borderRadius: '0px'
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    alt={item.description}
                                    style={{
                                        height: isMobile ? '82%' : '92%',
                                        width: '100%',
                                        objectFit: 'cover'
                                    }}
                                    image={item.image}
                                    title={item.description}
                                />
                                <Typography
                                    variant="h5"
                                    align="center"
                                    style={{ padding: '1rem' }}
                                >
                                    {item.description}
                                </Typography>
                            </Card>
                        </Link>
                    ))}
                </Carousel>
            </div>
            <Container>
                <Grid container spacing={4} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} sm={6}>
                        <Link to="/SongInsider" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Card>
                                <CardMedia
                                    component="img"
                                    alt="Cos'è SongInsider"
                                    height={isMobile ? '300' : '500'}
                                    image={logo}
                                    title="Cos'è SongInsider"
                                />
                                <Typography variant="h5" align="center" style={{ padding: '1rem' }}>
                                    Cos'è SongInsider
                                </Typography>
                            </Card>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card>
                            <Link to="/Interact" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <CardMedia
                                    component="img"
                                    alt="Come interagire con SongInsider"
                                    height={isMobile ? '300' : '500'}
                                    image={logo2}
                                    title="Come interagire con SongInsider"
                                />
                                <Typography variant="h5" align="center" style={{ padding: '1rem' }}>
                                    Come interagire con SongInsider
                                </Typography>
                            </Link>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Home;
